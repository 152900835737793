import { IMenuItem } from '@/components/molecules/DropDownMenu/DropDownMenu';

/**
 * GetShelterDashTabLinks's props
 *
 * @typedef GetShelterDashTabLinksProps
 */
type GetShelterDashTabLinksProps = {
  /** The active page */
  activePage?: 'pets' | 'admin' | 'profile' | 'toolkit' | 'reporting';
  /** Whether the user has the admin role */
  isAdmin?: boolean;
  /**
   * What variation to use. This is to be used with the multiUser feature flag
   * and should be removed once the feature flag is removed.
   *
   * - `multiUser`: for the multiUser experiment.
   * - `original`: for the original experience.
   */
  variation?: 'multiUser' | 'original';
  /** Wether the feature flag for the shelter dashboard reporting is enabled. */
  isFlagShelterDashboardReportingEnabled?: boolean;
};

/**
 * GetShelterDashTabLinks
 *
 * @param {GetShelterDashTabLinksProps} props - The props for the
 *   getShelterDashTabLinks function
 * @returns {object[]} - The navigation items for the shelter dashboard
 */
export function getShelterDashTabLinks({
  activePage,
  isAdmin,
  variation,
  isFlagShelterDashboardReportingEnabled = false,
}: GetShelterDashTabLinksProps) {
  const links = {
    pets: {
      label: 'Your Pets',
      link: '/shelter-dashboard/pets',
      active: activePage === 'pets',
    },
    admin: {
      label: 'Admin',
      link: '/shelter-dashboard/admin',
      active: activePage === 'admin',
    },
    profile: {
      label: 'Your Profile',
      link: '/shelter-dashboard/profile',
      active: activePage === 'profile',
    },
    toolkit: {
      label: 'Toolkit',
      link: `/shelter-dashboard/toolkit/`,
      active: activePage === 'toolkit',
    },
    reporting: {
      label: 'Reporting',
      link: '/shelter-dashboard/reporting',
      active: activePage === 'reporting',
    },
    helpCenter: {
      label: 'Help Center',
      link: 'https://support.partners.petcolove.org/hc/en-us',
    },
  };

  const adminLinks = [links.pets, links.toolkit, links.helpCenter];

  if (variation === 'multiUser' && isAdmin) {
    // Insert admin link after pets
    adminLinks.splice(1, 0, links.admin);
  }

  if (variation === 'original') {
    // Insert profile link after pets
    adminLinks.splice(1, 0, links.profile);
  }

  if (isFlagShelterDashboardReportingEnabled) {
    // Remove help center link
    adminLinks.pop();
    // Insert reporting link before the last admin link
    adminLinks.splice(isAdmin ? 2 : 1, 0, links.reporting);
  }

  return adminLinks;
}

/**
 * Get the variation value based on the MultiUser feature flag.
 *
 * This avoid "your profile" from flashing before disappearing if the flag is
 * enabled. That happens because the flag value is retrieved on the client and
 * its initial value is undefined.
 *
 * @param {boolean} featureFlag - The feature flag value
 * @returns {'multiUser' | 'original' | undefined} - The variation value
 */
export function getVariationFromMultiUserFlag(featureFlag?: boolean) {
  if (typeof featureFlag === 'undefined') {
    return undefined;
  }

  return featureFlag ? 'multiUser' : 'original';
}

/** GetShelterDashDropdownLinks's props */
type GetShelterDashDropdownLinksProps = Pick<
  GetShelterDashTabLinksProps,
  'isAdmin' | 'variation'
>;

/**
 * Get links for the shelter dashboard dropdown menu
 *
 * @param {GetShelterDashDropdownLinksProps} props - The props
 * @returns {IMenuItem[]} - The dropdown menu links
 */
export function getShelterDashDropdownLinks(
  props: GetShelterDashDropdownLinksProps
): IMenuItem[] {
  return getShelterDashTabLinks({
    variation: props.variation,
    isAdmin: props.isAdmin,
  }).map((item) => ({
    name: item.label,
    url: item.link,
  }));
}
